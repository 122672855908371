<template>
  <div class="page">
    <div class="title">账户设置</div>
    <div class="main">
      <div class="box">
        <div class="n1">
          <div class="hlhs pbgcolor"></div>
          <span class="txt">账号信息</span>
        </div>
        <div class="n2">
          <!-- <div class="item">
            <div class="itk">
              <el-avatar :src="checkStaticImg (avatar)"></el-avatar>
            </div>
            <div class="itv">
              <el-upload action="#" accept=".png,.jpeg" :show-file-list="false" :before-upload="beforeAvatarUpload"
                         :http-request="uploadimg_edit">
                <div class="action">修改头像</div>
              </el-upload>
            </div>
          </div> -->
          <div class="item">
            <div class="itk">昵称</div>
            <div class="itv">{{ name }}</div>
            <div class="action" @click="nickDialog = true">修改昵称</div>
          </div>
          <div class="item">
            <div class="itk">手机号</div>
            <div class="itv">{{ tel }}</div>
          </div>
          <div class="item">
            <div class="itk">微信号</div>
            <div class="itv" v-if="wxInfo" style="display: flex">
              <el-avatar :src="checkStaticImg(wxInfo.avatar)"></el-avatar>
              <div style="padding-top: 10px; padding-left: 10px">
                {{ wxInfo.nick }}
              </div>
              <div
                class="action"
                @click="unBind"
                style="padding-top: 8px; padding-left: 10px"
              >
                解绑微信
              </div>
            </div>
            <div class="itv action" v-else @click="bindwx">立即绑定</div>
          </div>
        </div>
      </div>
      <div class="box">
        <div class="n1">
          <div class="hlhs pbgcolor"></div>
          <span class="txt">店铺信息</span>
          <span style="font-size:12px">（最多只能添加五个店铺）</span>
        </div>
        <div class="n2">
          <div class="item">
            <div class="itk">ali店铺域名</div>
            <div class="itv" v-if="alsaddr">
              {{ alsaddr
              }}<i
                class="bindalsaddr action el-icon-edit"
                @click="show_chosealsaddr"
              ></i>
            </div>
            <div class="itv action" v-else @click="show_bindalsaddr">
              立即绑定
            </div>
          </div>
        </div>
      </div>
      <div class="box">
        <div class="n1">
          <div class="hlhs pbgcolor"></div>
          <span class="txt">邀请信息</span
          ><span style="font-size: 12px">（输入邀请码才效）</span>
        </div>
        <div class="n2">
          <div class="item">
            <div class="itk">邀请码</div>
            <div class="itv">{{ this.inviteCode }}</div>
          </div>
          <div class="item">
            <div class="itk">邀请链接</div>
            <div class="itv">
              <span class="copy-qiu">
                https://l-sou.com/register/register.html?inviteCode={{
                  this.inviteCode
                }}
              </span>
              <span>（来搜专业提效工具集插件注册网站）</span>
              <el-button
                class="copy-qiu"
                @click="copyQiu"
                data-clipboard-target=".copy-qiu"
                >复制链接</el-button
              >
            </div>
          </div>
        </div>
      </div>
      <div class="box">
        <div class="n1">
          <div class="hlhs pbgcolor"></div>
          <span class="txt">账户安全</span>
        </div>
        <div class="n2">
          <div class="item">
            <button @click="dialog_repwd = true">修改密码</button>
          </div>
        </div>
      </div>
    </div>
    <el-dialog
      :visible.sync="dialog_chosealsaddr"
      :showClose="false"
      width="500px"
    >
      <div slot="title">
        <span style="margin-right: 5px">选择ali店铺域名</span>
        <el-button type="primary" size="mini" @click="show_bindalsaddr"
          >添加店铺</el-button
        >
      </div>
      <el-select
        ref="select"
        v-model="selectAlsaddr"
        style="width: 450px"
        placeholder="请选择"
        @change="alsaddrChange"
      >
        <el-option
          v-for="item in alsaddrOptions"
          :key="item"
          :label="item"
          :value="item"
        >
          <span style="float: left">{{ item }}</span>

          <i
            class="el-icon-circle-close"
            @click.stop="handleDeleteTag(item, $event)"
            style="
              float: right;
              margin-left: 28px;
              margin-top: 10px;
              font-size: 15px;
              color: #ff6600;
            "
          ></i>
        </el-option>
      </el-select>
      <div slot="footer">
        <el-button @click="dialog_chosealsaddr = false">取 消</el-button>
        <el-button type="primary" @click="changealsaddr">提 交</el-button>
      </div>
    </el-dialog>
    <el-dialog
      :visible.sync="dialog_bindalsaddr"
      :closeOnClickModal="true"
      :showClose="false"
      :destroyOnClose="true"
      @close="rest('formbindalsaddr')"
      width="580px"
    >
      <div slot="title">
        <span style="margin-right: 5px">添加ali店铺域名</span>
      </div>
      <el-form
        :model="formbindalsaddr"
        ref="formbindalsaddr"
        label-width="80px"
        size="small"
        :rules="formbindalsaddrrules"
      >
        <el-form-item label="域名" prop="alsaddr">
          <el-input
            v-model="formbindalsaddr.alsaddr"
            class="widthmax"
            :maxlength="100"
          ></el-input>
        </el-form-item>
        <div style="margin-left: 80px; font-size: 12px">
          例如：https://xxxx.alibaba.com（网址格式请截止到com）
        </div>
      </el-form>
      <div slot="footer">
        <el-button @click="dialog_bindalsaddr = false">取 消</el-button>
        <el-button type="primary" @click="bindalsaddr">提 交</el-button>
      </div>
    </el-dialog>

    <el-dialog
      :visible.sync="dialog_repwd"
      :closeOnClickModal="true"
      :showClose="false"
      :destroyOnClose="true"
      @close="rest('formrepwd')"
      width="580px"
    >
      <div slot="title">
        <span>修改密码</span>
      </div>
      <el-form
        :model="formrepwd"
        ref="formrepwd"
        label-width="80px"
        size="small"
        :rules="formrepwdrules"
      >
        <el-form-item label="原始密码" prop="opwd">
          <el-input
            show-password
            v-model="formrepwd.opwd"
            class="widthmax"
            :maxlength="16"
          ></el-input>
        </el-form-item>
        <el-form-item label="新密码" prop="npwd">
          <el-input
            show-password
            v-model="formrepwd.npwd"
            class="widthmax"
            :maxlength="16"
          ></el-input>
        </el-form-item>
        <el-form-item label="密码确认" prop="npwd2">
          <el-input
            show-password
            v-model="formrepwd.npwd2"
            class="widthmax"
            :maxlength="16"
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button @click="dialog_repwd = false">取 消</el-button>
        <el-button type="primary" @click="repwd">提 交</el-button>
      </div>
    </el-dialog>
    <el-dialog
      :visible.sync="nickDialog"
      :closeOnClickModal="false"
      :showClose="false"
      :destroyOnClose="true"
      width="580px"
    >
      <el-input
        v-model="nickname"
        placeholder="请输入新的昵称"
        :maxlength="11"
      ></el-input>
      <div slot="footer">
        <el-button @click="nickDialog = false">取 消</el-button>
        <el-button type="primary" @click="confirmNick">提 交</el-button>
      </div>
    </el-dialog>
    <el-dialog
      :visible.sync="wxLoginDalog"
      width="400px"
      :showClose="true"
      :closeOnClickModal="true"
    >
      <div class="wechat" id="loginContainer"></div>
    </el-dialog>
  </div>
</template>

<script>
import global from "@/plugins/global";
import Clipboard from "clipboard";
export default {
  data() {
    return {
      name: "",
      tel: "",
      wx: "",
      wxInfo: null,
      alsaddr: "",
      selectAlsaddr: "",
      avatar: "",
      nickDialog: false,
      nickname: "",
      injectId: "",
      inviteCode: "",
      wxLoginDalog: false,
      dialog_bindalsaddr: false,
      dialog_chosealsaddr: false,
      alsaddrOptions: [],
      isDelete: false,
      deletealsaddr: "",
      newalsaddr: "",
      tagisShow: false,
      formbindalsaddr: {
        alsaddr: "",
      },
      formbindalsaddrrules: {
        alsaddr: [
          { required: true, message: "请输入ali店铺域名", trigger: "blur" },
          {
            pattern: /https:\/\/[^\s]*\.alibaba.com\/?$/,
            message: "域名格式错误",
          },
        ],
      },
      dialog_repwd: false,
      formrepwd: {
        opwd: "",
        npwd: "",
        npwd2: "",
      },
      formrepwdrules: {
        opwd: [
          { required: true, message: "请输入原密码", trigger: "blur" },
          { pattern: /^\S{8,16}$/, message: "密码格式为8-16位非空白字符" },
        ],
        npwd: [
          { required: true, message: "请输入新密码", trigger: "blur" },
          { pattern: /^\S{8,16}$/, message: "密码格式为8-16位非空白字符" },
        ],
        npwd2: [
          { required: true, message: "请输入密码确认", trigger: "blur" },
          { pattern: /^\S{8,16}$/, message: "密码格式为8-16位非空白字符" },
        ],
      },
    };
  },
  created() {
    this.getInjectId();
  },
  mounted() {
    this.getuserinfo();
  },
  computed: {
    // avatar2() {
    //   if (this.avatar) {
    //     return 'https://api.l-sou.com' + this.avatar;
    //   }
    // }
  },
  methods: {
    mouseover(data) {
      if (data) {
        console.log("asdasdas");
        this.tagisShow = true;
      }
    },
    async unBind() {
      await this.axios.post("/users/unbindwx");
      this.getuserinfo();
    },
    checkStaticImg(url) {
      console.info("global === ", this.global);
      console.log("asdasdasdas", url);

      if (url !== null) {
        if (url.indexOf("/static") === 0) {
          return this.global.baseurl + url;
        }
      }

      return url;
    },
    getInjectId() {
      this.axios.get("/index/getinject").then((data) => {
        this.injectId = data.data;
      });
    },
    getWxInfo() {
      this.axios.get("/users/userwx").then((data) => {
        this.wxInfo = data.data;
      });
    },

    copyQiu() {
      let clipboard = new Clipboard(".copy-qiu");
      console.log("safasf", clipboard);
      clipboard.on("success", () => {
        // this.showToast('复制成功')
        // 释放内存
        this.$message.success("复制成功！");
        clipboard.destroy();
      });

      clipboard.on("error", () => {
        // 不支持复制
        this.$message.error("复制失败，请手动复制");
        // 释放内存
        clipboard.destroy();
      });
    },
    initWx() {
      let token = sessionStorage.getItem("lsou_token");
      let redirect_uri = encodeURIComponent(
        "https://api.l-sou.com/users/public/bindwx?jwt=" + token
      );
      this.wxObj = new WxLogin({
        self_redirect: true,
        id: "loginContainer",
        appid: "wxd06320aeda2a211f",
        scope: "snsapi_login",
        response_type: "code",
        redirect_uri: redirect_uri,
        state: this.injectId,
        // style: "",
        href: "https://l-sou.com/css/wc.css",
      });
    },
    confirmNick() {
      if (this.nickname.length < 1 || this.nickname.length > 11) {
        return this.$message.error("昵称长度不合法");
      }
      this.axios
        .post("/users/updatenick", { nick: this.nickname })
        .then((data) => {
          this.name = this.nickname;
          this.nickDialog = false;
        })
        .catch((e) => {
          this.$message.error("修改失败，请稍后再试或联系客服");
          this.nickDialog = false;
        });
    },
    getuserinfo() {
      this.axios.get("/users/getuserinfo").then((res) => {
        console.log("asfsf", res);
        if (res.code === this.global.CODE.SUCCESS) {
          this.inviteCode = res.data.icode;
          this.tel = res.data.tel;
          this.wx = res.data.wx;
          this.alsaddr = res.data.alsaddr;
          this.selectAlsaddr = res.data.alsaddr;
          this.alsaddrOptions =
            res.data.alsaddrList == "" ? null : res.data.alsaddrList;
          this.avatar = res.data.avatar;
          this.name = res.data.nick;
          this.getWxInfo();
        }
      });
    },
    repwd() {
      this.$refs["formrepwd"].validate((valid) => {
        if (!valid) {
          return false;
        }
        if (this.formrepwd.npwd !== this.formrepwd.npwd2) {
          this.$message.error("密码不一致");
          return false;
        }
        this.axios
          .post("/users/updatepwd", {
            opwd: this.formrepwd.opwd,
            npwd: this.formrepwd.npwd,
            npwd2: this.formrepwd.npwd2,
          })
          .then((res) => {
            if (res.code === this.global.CODE.SUCCESS) {
              this.$message.success("修改成功");
              this.dialog_repwd = false;
            }
          });
      });
    },
    beforeAvatarUpload(file) {
      const isJPG = file.type === "image/jpeg" || file.type === "image/png";
      if (!isJPG) {
        this.$message.error("仅支持jpeg或png格式");
      }
      const isLt2M = file.size / 1024 / 1024 < 1;
      if (!isLt2M) {
        this.$message.error("图片大小不能超过1MB");
      }
      return isJPG && isLt2M;
    },
    uploadimg_edit(param) {
      let file = param.file;
      const form = new FormData();
      form.append("img", file);
      this.axios
        .post("users/updateavatar", form, {
          headers: { "content-type": "multipart/form-data" },
        })
        .then((res) => {
          this.avatar = res.data;
          this.getuserinfo();
        });
    },
    bindwx() {
      this.wxLoginDalog = true;
      window.addEventListener("message", (e) => {
        let type = e.data.type;
        console.info("message", e);
        if (type === "wxBind") {
          let result = e.data.data;
          this.wxInfo = result;
          this.wx = result.wx;
          console.info("wx bind result = ", result);
          this.wxLoginDalog = false;
        }
      });
      this.$nextTick(() => {
        this.initWx();
      });
    },
    show_bindalsaddr() {
      this.dialog_bindalsaddr = true;
    },
    show_chosealsaddr() {
      console.log("dsadsad");
      this.getuserinfo();
      this.dialog_chosealsaddr = true;
    },
    handleDeleteTag(e) {
      this.isDelete = true;
      this.alsaddrOptions.splice(
        this.alsaddrOptions.findIndex((item) => item === e),
        1
      );

      this.selectAlsaddr == e
        ? (this.selectAlsaddr = this.alsaddrOptions[0])
        : this.selectAlsaddr;
      this.$refs.select.visible = false;
      console.log("fdsfsdfds", this.alsaddrOptions);
    },
    deleteAlsaddr() {
      this.axios
        .post("/users/deleteAlsaddr", {
          alsaddrList: this.alsaddrOptions.toString(),
        })
        .then((res) => {
          if (res.code === this.global.CODE.SUCCESS) {
            this.$message.success("修改成功");
            this.isDelete = false;
            this.dialog_chosealsaddr = false;
            this.getuserinfo();
          }
        });
    },
    bindalsaddr() {
      this.$refs["formbindalsaddr"].validate((valid) => {
        if (!valid) {
          return false;
        }
        this.axios
          .post("/users/addAlsaddr", {
            alsaddr: this.formbindalsaddr.alsaddr,
          })
          .then((res) => {
            if (res.code === this.global.CODE.SUCCESS) {
              this.$message.success("修改成功");
              this.dialog_bindalsaddr = false;
              this.getuserinfo();
            }
          });
      });
    },
    alsaddrChange(e) {
      this.newalsaddr = e;
      console.log("asdsdsd", e);
    },
    updateAlsaddr() {
      this.axios
        .post("/users/updatealsaddr", {
          alsaddr: this.newalsaddr,
        })
        .then((res) => {
          if (res.code === this.global.CODE.SUCCESS) {
            console.log("sdffsafsafa", res);
            this.$message.success("修改成功");
            this.newalsaddr = "";
            this.dialog_chosealsaddr = false;
            this.getuserinfo();
          }
        });
    },
    deleteAndupdateAlsaddr() {
      this.axios
        .post("/users/deleteAlsaddr", {
          alsaddrList: this.alsaddrOptions.toString(),
        })
        .then((res) => {
          if (res.code === this.global.CODE.SUCCESS) {
            this.dialog_chosealsaddr = false;
            this.updateAlsaddr();
          }
        });
    },
    changealsaddr() {
      if (
        (this.newalsaddr == "" && this.isDelete == false) ||
        (this.newalsaddr == this.alsaddr && this.isDelete == false)
      ) {
        this.dialog_chosealsaddr = false;
      } else if (this.newalsaddr == "" && this.isDelete) {
        this.deleteAlsaddr();
      } else if (this.newalsaddr != "" && !this.isDelete) {
        this.updateAlsaddr();
      } else if (this.newalsaddr != "" && this.isDelete) {
        this.deleteAndupdateAlsaddr();
      }
    },

    rest(formname) {
      this.$refs[formname].resetFields();
    },
  },
};
</script>

<style scoped>
.title {
  font-size: 18px;
  font-weight: bold;
  color: #313131;
}

.main {
  min-width: 900px;
  background-color: #fff;
  border-radius: 12px;
  margin: 15px 0;
  padding: 28px;
}

.main .box {
  border-radius: 12px;
  box-shadow: 0 2px 5px 0 #ddd;
  margin-top: 30px;
  padding: 20px;
  color: #666;
}

.main .box .bindalsaddr {
  margin: 0 10px;
}

.box .n1 {
  display: flex;
  align-items: center;
}

.box .n1 .hlhs {
  /*background-color: #FF6A00;*/
  width: 2px;
  height: 16px;
}

.box .n1 .txt {
  margin-left: 10px;
}

.box .n2 .item {
  display: flex;
  align-items: center;
  font-size: 14px;
  margin-top: 20px;
}

.box .n2 .item .action {
  color: #ff6a00;
}

.box .n2 .item .action:hover {
  cursor: pointer;
}

.box .n2 .item .itv {
  margin: 0 20px;
}

.item button {
  height: 30px;
  line-height: 30px;
  padding: 0 12px;
  border: 1px solid #fff;
  border-radius: 8px;
  background-color: #ff6a00;
  color: #fff;
}

button:hover {
  cursor: pointer;
}

button:active {
  color: #eee;
}
.wechat {
  padding-left: 30px;
}
</style>
